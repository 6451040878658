import VueRouter from 'vue-router';
// Pages
import ITStartup from './components/landing-pages/Metricbees.vue';


export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
    routes: [
      { path: '/', component: ITStartup },
    ]
});