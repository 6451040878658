<template>
  <!-- Start Design & Development Services -->
  <section class="services-area ptb-80">
    <div class="container fluid">
      <div class="title">
        About Us
      </div>
      <div :style="cssProps">
        <b-row>
          <b-col cols="12" lg="5">
            <div
              class="hero-content"
              style="padding-bottom: 0px; margin-bottom: 0px"
            >
              <h1>
                Decades of proven expertise in the data arena between the
                founders
              </h1>
            </div>
          </b-col>
          <b-col lg="2" />
          <b-col>
            <!-- <b-card
              overlay
              img-src="../../../assets/img/Metricbees/aws.jpeg"
              img-alt="Card Image"
              border-variant="white"
              bg-variant="transparent"
            >
            </b-card> -->
          </b-col>
        </b-row>
        <b-row >
          <b-col cols="12" >
            <b-card class=t-box
              bg-variant="transparent text-align: left"
            >
              <p
                class="hero-content box"
                style="position:relative; left:0px; top:0px; width: 100% ; text-align:justify; color:black"
              >
                A team of seasoned minds that you can vouch and not easily available in the
                market, capable of revolutionising the way data can contribute
                to your business upliftment. On course of our exciting
                international work journeys, been part of some phenomenal
                implementations and services, few excerpts below,</p>
                <p class="hero-content box"
                style="position:relative; left:0px; top:0px; width: 100%; text-align:justify; color:black">

                <!--
                <ul>
                  <li style=" text-align:justify">
                 <span style="text-decoration: underline"> E-Commerce </span>-
                Architected and executed end-to-end Big data Analytics solution
                spanning 15+global countries (from scratch) for an Asian
                electronics giant.</li> <li style=" text-align:justify"> <span style="text-decoration: underline"> Banking and Finance </span>- Played instrumental
                roles in Top global banks managing credit risk profile and
                regulatory governance. </li> <li style=" text-align:justify"> <span style="text-decoration: underline"> Transportation </span>- For a UK national
                transport, built a solid platform , executing an Asset
                degradation prediction engine for maintenance and renewal of
                infrastructure assets. </li> <li style=" text-align:justify"> <span style="text-decoration: underline"> Insurance </span>- Instrumental in delivering
                Life Earning analytics for a French insurance firm, and a robust
                data migration solution on course of merger with a Swiss firm
                And much more..</li> </ul>
                 -->
              
            
                <span style="text-decoration: underline; text-align:justify"> E-Commerce</span> -
                Architected and executed, end-to-end Big data Analytics solution
                spanning 15+global countries (from scratch) for an Asian
                electronics giant.<br> <br>
                 <span style="text-decoration: underline; text-align:justify"> Banking and Finance</span> - Played instrumental
                roles in Top global banks managing credit risk profile and
                regulatory governance.<br> <br>
                <span style="text-decoration: underline; text-align:justify"> Transportation</span> - For a UK national
                transport, built a solid platform , executing an Asset
                degradation prediction engine for maintenance and renewal of
                infrastructure assets. <br><br>
                <span style="text-decoration: underline; text-align:justify"> Insurance</span> -  Instrumental in delivering
                Life Earning analytics for a French insurance firm, and a robust
                data migration solution on course of merger with a Swiss firm <br>
                And much more..


                <br><br>Enrol and entrust us to roll on your path to
                insights-driven decisions.
                
              </p>
       
    <div class="shape7">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape4">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
  <!-- End Design & Development Services -->
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {
      cssProps: {
        backgroundImage: `url(${require("../../../assets/img/Metricbees/plane.png")})`,
        backgroundPosition: "25% 25%",
        backgroundSize: "400px 700px",
        backgroundRepeat: "no-repeat",
        objectfit: "fill",
      },
    };
  },
};
</script>
<style scoped>
ul {
  list-style: none;
}

ul li:before {
  content: '✓ ';
}
</style>
