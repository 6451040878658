<template>
  <!-- Start Design & Development Services -->
  <section class="services-area ptb-80" id="whyuS" ref="whyus">
    <div class="container fluid">
      <div class="title">
        Why Us
      </div>
      <div>
        <b-row>
          <b-col cols="12" lg="4">
            <div class="hero-content">
              <h1>
                Excelled in providing top-notch solutions to industries such as
                Ecommerce, Banking and Finance, Insurance, Transport and many
                more...
              </h1>
            </div>
          </b-col>
          <b-col cols="1" />
          <b-col class="box" cols="12" lg="7">
            <b-row style="padding:10px">
              <b-col cols="12" lg="6">
                <b-card
                  :class="[cust ? null : 'collapsed', 'single-box']"
                  :aria-expanded="cust ? 'true' : 'false'"
                  aria-controls="cust"
                  @mouseover="cust = true"
                  @mouseout="cust = false"
                  style="padding: 0px"
                >
                  <b-row>
                    <b-col cols="10">
                      <div class="icon">
                        <img
                          src="../../../assets/img/Metricbees/Group 48@2x.png"
                        />
                      </div>
                    </b-col>
                    <b-col cols="2">
                      <feather type="chevron-down" v-if="!cust"></feather>
                    </b-col>
                  </b-row>
                  <b-collapse id="cust" v-model="cust" class="mt-10">
                    <b-card style="margin-top: 10px">
                      <p>
                        Keep it simple. We believe in the words "When you want
                        to serve the customer become the customer" and make each
                        and every customer satisfaction count
                      </p></b-card
                    >
                  </b-collapse>
                  <h4>Customer Centric</h4>
                </b-card>
              </b-col>
              <b-col cols="12" lg="6">
                <b-card
                  :class="[result ? null : 'collapsed', 'single-box']"
                  :aria-expanded="result ? 'true' : 'false'"
                  aria-controls="result"
                  @mouseover="result = true"
                  @mouseout="result = false"
                  style="padding: 0px"
                >
                  <b-row>
                    <b-col cols="10">
                      <div class="icon">
                        <img
                          src="../../../assets/img/Metricbees/Group 36@2x.png"
                        />
                      </div>
                    </b-col>
                    <b-col cols="2">
                      <feather type="chevron-down" v-if="!result"></feather>
                    </b-col>
                  </b-row>
                  <b-collapse id="result" v-model="result" class="mt-10">
                    <b-card style="margin-top: 10px">
                      <p>
                        All our offerings are defined with set of pre-agreed
                        results, which are the fruit bearing elements for the
                        organisation. We leave no stone unturned and fire every
                        arrow from our quiver to attain the bulls eye.
                      </p></b-card
                    >
                  </b-collapse>
                  <h4>Results Oriented</h4>
                </b-card>
              </b-col>
            </b-row>
            <b-row style="padding:10px">
              <b-col cols="12" lg="6">
                <b-card
                  :class="[quality ? null : 'collapsed', 'single-box']"
                  :aria-expanded="quality ? 'true' : 'false'"
                  aria-controls="quality"
                  @mouseover="quality = true"
                  @mouseout="quality = false"
                  style="padding: 0px"
                >
                  <b-row>
                    <b-col cols="10">
                      <div class="icon">
                        <img
                          src="../../../assets/img/Metricbees/Group 38@2x.png"
                        />
                      </div>
                    </b-col>
                    <b-col cols="2">
                      <feather type="chevron-down" v-if="!quality"></feather>
                    </b-col>
                  </b-row>
                  <b-collapse id="quality" v-model="quality" class="mt-10">
                    <b-card style="margin-top: 10px">
                      <p>
                        Timely delivery and top notch quality are the backbone
                        of our offerings. All artefacts and solutions go through
                        the fire drill to ensure they are quality personified.
                      </p></b-card
                    >
                  </b-collapse>
                  <h4>Quality Driven</h4>
                </b-card>
              </b-col>
              <b-col cols="12" lg="6">
                <b-card
                  :class="[perszd ? null : 'collapsed', 'single-box']"
                  :aria-expanded="perszd ? 'true' : 'false'"
                  aria-controls="perszd"
                  @mouseover="perszd = true"
                  @mouseout="perszd = false"
                  style="padding: 0px"
                >
                  <b-row>
                    <b-col cols="10">
                      <div class="icon">
                        <img
                          src="../../../assets/img/Metricbees/Group 42@2x.png"
                        />
                      </div>
                    </b-col>
                    <b-col cols="2">
                      <feather type="chevron-down" v-if="!perszd"></feather>
                    </b-col>
                  </b-row>
                  <b-collapse id="perszd" v-model="perszd" class="mt-10">
                    <b-card style="margin-top: 10px">
                      <p>
                        A vanilla solution does not serve all. As well, building
                        solutions from scratch turn into a time-consuming and
                        costly affair. We blend the best of both worlds,
                        following a bottom up approach driving from customer
                        needs, providing tailor made solutions at Vanilla cost.
                      </p></b-card
                    >
                  </b-collapse>
                  <h4>Personalized</h4>
                </b-card>
              </b-col>
              <b-col
                class="hero-content box"
                style="padding:0px; margin: 0px"
                md="12"
              >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-card class=t-box>
        <b-row>
          <b-col cols="12">
            <p class="box" style="margin-top:30px;color:black ; text-align:justify">
              WE CARE - Customize, Adapt, Revolutionize and assist the
              organisation Evolve.
              <br> <br>
              But first things first, honest truth be - data has to be first
              recorded, cleansed, standardised and modelled to act as a solid
              foundation, that can be extended and enhanced at ease for the
              future data demands. Building it right way is like sowing the
              seeds for a ripening harvest. 
              <br> <br>
              Everything that follows starting from simple daily operational
              reports to advanced analytics, AI /ML & IOT is dependent on setting the base right. 
              Though the end business user focus is on
              insights, predictions and prescriptive analytics, what happens
              behind the scenes, play a crucial part. We are vocal in explaining
              this since TRUST is an important factor. Nothing to hide, its all
              clear and transparent.
              <br />
              <br />
              Fully understand the depth and breadth of
              what it takes to future proof the data management for the
              customer.
              <br>
            </p>
          </b-col>
        </b-row>
    <div class="shape7">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape4">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
        </b-card>
      </div>
    </div>
  </section>
  <!-- End Design & Development Services -->
</template>

<script>
export default {
  name: "WhyUS",
  data() {
    return {
      cust: false,
      result: false,
      quality: false,
      perszd: false,
    };
  },
};
</script>
`
