<template>
  <!-- Start Design & Development Services -->
  <section class="services-area ptb-80">
    <div class="container">
      <div class="title">
        Products & Services
      </div>
      <b-row>
        <b-col class="box" cols="12" lg="5">
          <b-row>
            <b-col cols="12">
              <div class="hero-content">
                <h1>
                  Common challenges brain stormed into ready-made artefacts.
                </h1>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="pricing-table">
            <div class="pricing-header">
              <b-row>
                <b-col cols="2">
                  <div class="icon">
                    <video
                      autoplay
                      playsinline
                      muted
                      loop
                      id="myVideo"
                      class="myVideo"
                    >
                      <source
                        src="../../../assets/img/Metricbees/bconnect.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col cols="6" style="margin-top:12px">
                  <h3 style="color:black">b-Connect</h3>
                </b-col>
              </b-row>
            </div>
            <div
              class="pricing-box"
              style="background: none; padding-top:0px;padding-bottom:0px"
            >
              <b-row>
                <b-col cols="12">
                  <p style=" padding-bottom:30px; text-align: left">
                    Pre-built connectors that extract data in a seamless
                    fashion.
                  </p>
                </b-col>
              </b-row>
              <div class="pricing-features">
                <ul>
                  <li class="active">
                    Readymade Connectors
                  </li>
                  <li class="active">
                    No Development Effort
                  </li>
                  <li class="active">
                    Plug & Play
                  </li>
                  <li class="active">
                    Near real-time
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="pricing-table">
            <div class="pricing-header">
              <b-row>
                <b-col cols="2">
                  <div class="icon">
                    <video
                      autoplay
                      playsinline
                      muted
                      loop
                      id="myVideo"
                      class="myVideo"
                    >
                      <source
                        src="../../../assets/img/Metricbees/Funnel.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col cols="5" style="margin-top:12px">
                  <h3 style="color:black">i-Cleanse</h3>
                </b-col>
              </b-row>
            </div>

            <div
              class="pricing-box"
              style="background: none; padding-top:0px;padding-bottom:0px"
            >
              <b-row>
                <b-col cols="12">
                  <p style=" padding-bottom:30px; text-align: left">
                    Intelligent Cleansing Layer to pre-process & standardise the
                    data.
                  </p>
                </b-col>
              </b-row>

              <div class="pricing-features">
                <ul>
                  <li class="active">
                    Automated Data Quality
                    Checks
                  </li>
                  <li class="active">
                    Rules Ingestion
                  </li>
                  <li class="active">
                    Data Profiling
                  </li>
                  <li class="active">
                    Self-learnt matching and
                    de-duplication
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
        >
          <div class="pricing-table">
            <div class="pricing-header">
              <b-row>
                <b-col cols="2">
                  <div class="icon" cols="6">
                    <video
                      autoplay
                      playsinline
                      muted
                      loop
                      id="myVideo"
                      class="myVideo"
                    >
                      <source
                        src="../../../assets/img/Metricbees/tasks.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </b-col>
                <b-col cols="1"></b-col>
                <b-col cols="6" style="margin-top:12px">
                  <h3 style="color:black">i-Karya</h3>
                </b-col>
              </b-row>
            </div>

            <div
              class="pricing-box"
              style="background: none; padding-top:0px;padding-bottom:0px"
            >
              <b-row>
                <b-col cols="12">
                  <p style=" padding-bottom:30px; text-align: left">
                    A single place to define, record, manage and govern all
                    tasks in your workplace
                  </p>
                </b-col>
              </b-row>

              <div class="pricing-features" >
                <ul>
                  <li class="active">
                    Convert your emails into
                    Tasks
                  </li>
                  <li class="active">
                    Operational Dashboards
                  </li>
                  <li class="active">SaaS</li>
                  <li class="active">
                    Data governance personified
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br><br>
      <b-card class=t-box>
      <p style="color:black">
        Provide services, catering to business needs and facilitate the complete data
        life-cycle. <br>
        <ul>
          <li>Is your data cluttered across many systems working as
        independent silos, and looking to organise it for a holistic
        functioning? </li><li>Already have a data pipeline & analytics system, and face
        any challenges or looking to extend?</li><li>Moving your system from on-premises
        to Cloud? </li><li>Starting to understand the importance of data and need help in
        facilitation, right from data recording, governance and to get data insights
        to aid your business decisions ?</li></ul>We can make it look simple and provide end-to-end
        data solutions that adds value to your business for every unique
        requirement. <br>Be it, <br><ul><li>A complex business transformation program</li><li>Full
        fledged big data solution with near real time analytics to run your
        business </li><li>AI & ML to drive your predictions/forecasting/customer
        propensity</li><li> Cumbersome Data migration as part of enterprise mergers</li></ul>
        <span>
        <p style="color:black;text-align: justify">
        Wherever your data is stored and whatever format - Ranging from ERPs,
        CRMs, External APIs, spreadsheets or functional systems like Supply
        chain, Inventory, Logistics, Payments, Campaign management or any
        connected system; our solution can seamlessly integrate data across all
        these systems and provide a bird’s eye view of the State of play.
        </p>
        </span>
      </p>
    <div class="shape7">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape4">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
      </b-card>
    </div>

   
  </section>
  <!-- End Design & Development Services -->
</template>

<script>
export default {
  name: "ServicesArea",
  data() {
    return {
      extract: false,
      organise: false,
      visualise: false,
      innovate: false,
      insights: false,
    };
  },
};
</script>
<style scoped>
.myVideo {
  position: relative;
  z-index: 0;
  width: 50px;
  height: auto;
  right: 0;
  bottom: 0;
  object-fit: contain;
}
</style>
