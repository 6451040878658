<template>
    <!-- Start Navbar Area -->
    <header id="header" :class="['headroom', {'is-sticky': isSticky}]">
        <scrollactive class="startp-nav">
            <div class="container-fluid" style="padding: 0px 20px 0px 20px">
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/img/mblogo.jpg" width="193" height="53" alt="logo" />
                    </router-link>

                    <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>


                    <b-collapse class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" is-nav>
                        <b-navbar-nav nav class="ml-auto">
                            <b-nav-item class="active">
                                <a href="#home" data-toggle="collapse" data-target=".navbar-collapse.show" class="scrollactive-item">
                                    HOME
                                </a>
                            </b-nav-item>

                            <b-nav-item ><a href="#whyus" data-toggle="collapse" data-target=".navbar-collapse.show" class="scrollactive-item">WHY US</a>
                            </b-nav-item>

                            <b-nav-item>
                                <a href="#approach" data-toggle="collapse" data-target=".navbar-collapse.show" class="scrollactive-item">OUR APPROACH</a>
                            </b-nav-item>
                            
                            <b-nav-item>
                                <a href="#products" data-toggle="collapse" data-target=".navbar-collapse.show" class="scrollactive-item">PRODUCTS & SERVICES</a>
                            </b-nav-item>
                            
                            <b-nav-item>
                                <a href="#benefits" data-toggle="collapse" data-target=".navbar-collapse.show" class="scrollactive-item">BENEFITS</a>
                            
                            </b-nav-item>

                            <b-nav-item>
                                 <a href="#about" data-toggle="collapse" data-target=".navbar-collapse.show" class="scrollactive-item">ABOUT US</a>
                            
                            </b-nav-item>
                             <b-nav-item>
                                 <a href="#contact"  data-toggle="collapse" data-target=".navbar-collapse.show" class="scrollactive-item">CONTACT US</a>
                            
                            </b-nav-item>

                            

                        </b-navbar-nav>
                    </b-collapse>

                </nav>
            </div> 
        </scrollactive>
    </header>
    <!-- End Navbar Area -->
</template>

<script>

export default {
    name: 'Header',
    data(){
        return {
            isSticky: false
        }
    },

    mounted() {
        const that = this;
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY;
            // eslint-disable-next-line no-console
            if(scrollPos >= 100){
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        })
    },

    computed: {
        shoppingCart(){
            return this.$store.state.cart;
        }
    }
}
</script>