<template>
  <!-- Start Main Banner -->
  <div class="main-banner" v-bind:style="{'background': 'none !important' , 'height': this.window.height}">
    <div class="overlay">
      <b-row style=" margin: 0px">
        <b-col cols="12" md="4" sm="12" xs="12">
          <p class="banner-title">
            Insightful <br />
            Innovation leads to <br />
            <span style="color: red">Increased ROI</span>
          </p>
        </b-col>
        <b-col cols="12" md="6" sm="12" xs="12">
          <p class="banner-desc" style="text-align:justify; color:black">

            The Modern world has transpired a data storm to create an
            ocean of data across the universe. Anything we touch, see and record
            in any form is data. We act as the fulcrum to churn
            the data for you and deliver the essence (insights),
            leading the way to innovation...
          </p>
        </b-col>
        <b-col cols="12" md="1" sm="12" xs="12" >
          <b-img v-if=desktop class="img" thumbnail  src="../../../assets/img/Metricbees/aws.jpeg"   alt="Fluid image"></b-img>
        </b-col>
        
      </b-row>
    </div>
    <div>
      <video autoplay playsinline muted loop id="myVideo" class="myVideo" background="none" v-if=desktop>
        <source
          src="../../../assets/img/Metricbees/Metricbees banner illustration 550 .mp4"
          type="video/mp4"          
        />
      </video> 
      <video autoplay playsinline muted loop id="myVideo" class="myVideo" background="none" v-else>
        <source
          src="../../../assets/img/Metricbees/banner_Mobile.webm"
          type="video/webm"
        />
        <source          
          src="../../../assets/img/Metricbees/banner_Mobile.mp4"
          type="video/mp4"          
        />
      </video> 
    </div>
  </div>
  <!-- End Main Banner -->
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
        window: {
            width: 0,
            height: 0
        }
    }
    },
    computed:{
      desktop() {
       return  (window.innerWidth > 771) ? true:false
      }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth+"px";

            if (window.innerWidth > 771) {
      this.window.height = (window.innerHeight)+"px";
    } else {
      this.window.height = (window.innerHeight)+"px";
    }
        }
    },
    mounted() {
     window.addEventListener('resize', this.handleResize);
        this.handleResize();

    }
};
</script>
<style scoped>

</style>
