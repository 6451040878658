<template>
  <div >
    <a hidden name="home" id="home"></a>
    <Banner></Banner>
    <a name="whyus" id="whyus"></a>
   <WhyUS></WhyUS>
   <a name="approach" id="approach"></a>
    <our-approach></our-approach> 
    <a name="products" id="products"></a>
    <products-services />
    <a name="benefits" id="benefits"></a>
    <benefits />
    <a name="about" id="about"></a>
    <about-us />

    <!-- Start Ready To Talk Area -->
    <a name="contact" id="contact"></a>
    <div class="ready-to-talk" styles="padding-bottom: 0px">
      <div class="container">
        <h3>Ready to talk?</h3>
        <p>Our team is here to answer your questions</p>
        <h3>Mail us</h3>
        <p>info@metricbees.com</p>
      </div>
    </div>
    <!-- End Ready To Talk Area -->
  </div>
</template>

<script>
import Banner from "./Metricbees/Banner";
import WhyUS from "./Metricbees/WhyUS";
import ourApproach from "./Metricbees/Approach";
import ProductsServices from "./Metricbees/ProductsServices.vue";
import Benefits from "./Metricbees/Benefits.vue";
import AboutUs from "./Metricbees/AboutUs.vue";
export default {
  name: "Metricbees",
  components: {
    Banner,
    WhyUS,
    ourApproach,
    ProductsServices,
    Benefits,
    AboutUs
  },
  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
  },
};
</script>
