<template>
  <!-- Start Design & Development Services -->
  <section class="services-area ptb-80">
    <div class="container">
      <div class="title">
        Our Approach
      </div>
      <div>
        <b-row>
          <b-col class="box" cols="12" lg="5">
            <b-row>
              <b-col cols="12">
                <div class="hero-content">
                  <h1>
                    Drive the organisation from Data to Innovation
                  </h1>
                </div>
              </b-col>
                <b-col cols="12" >
                  <b-card 
                      :class="[extract ? null : 'collapsed' , 'single-box']"
                      :aria-expanded="extract ? 'true' : 'false'"
                      aria-controls="extract"
                      @mouseover= "extract = true"
                      @mouseout= "extract = false"
                      style="padding: 0px"
                     >
                    <b-row>
                      <b-col cols=9>
                        <h4 >Extract</h4>
                      </b-col> <b-col cols=2 style="padding-top:20px;color:#434EB8" v-if="!extract">
                         <feather type="chevron-down"></feather>
                         </b-col>
                    </b-row>
                    <b-collapse id="extract" v-model="extract" class="mt-0">
                      <b-card><p>Data drawn out from all Organisational and connected entities including public available data, pooled into a common data lake, ready for further processing</p></b-card>
                    </b-collapse>
                  </b-card>
                </b-col>
                <b-col cols="12">
                  <b-card 
                      :class="[organise ? null : 'collapsed' , 'single-box']"
                      :aria-expanded="organise ? 'true' : 'false'"
                      aria-controls="organise"
                      @mouseover= "organise = true"
                      @mouseout= "organise = false"
                      style="padding: 0px"
                     ><b-row>
                      <b-col cols=9>
                        <h4 >Organize</h4>
                      </b-col> <b-col cols=2 style="padding-top:20px;color:#434EB8" v-if="!organise">
                         <feather type="chevron-down"></feather>
                         </b-col>
                    </b-row>
                    
                    <b-collapse id="organise" v-model="organise" class="mt-0" >
                      <b-card><p>Robust frameworks that consume the raw data, cleanse, transform and slide them through to business models that are personalised to industry requirements</p></b-card>
                    </b-collapse>
                  </b-card>
                </b-col>
                <b-col cols="12">
                  <b-card 
                      :class="[visualise ? null : 'collapsed' , 'single-box']"
                      :aria-expanded="visualise ? 'true' : 'false'"
                      aria-controls="visualise"
                      @mouseover= "visualise = true"
                      @mouseout= "visualise = false"
                      style="padding: 0px"
                     >
                    <b-row>
                      <b-col cols=9>
                        <h4 >Visualize</h4>
                      </b-col> <b-col cols=2 style="padding-top:20px;color:#434EB8" v-if="!visualise">
                         <feather type="chevron-down"></feather>
                         </b-col>
                    </b-row>
                    
                    <b-collapse id="visualise" v-model="visualise" class="mt-0">
                      <b-card><p>Draw your attention to Pragmatic dashboards that present a holistic picture of organisation. See the data speak in a more related way to your business</p></b-card>
                    </b-collapse>
                  </b-card>
                </b-col>
               <b-col cols="12">
                 <b-card 
                      :class="[insights ? null : 'collapsed' , 'single-box']"
                      :aria-expanded="insights ? 'true' : 'false'"
                      aria-controls="insights"
                      @mouseover= "insights = true"
                      @mouseout= "insights = false"
                      style="padding: 0px"
                     >
                    <b-row>
                      <b-col cols=9>
                        <h4 >Insights</h4>
                      </b-col> <b-col cols=2 style="padding-top:20px;color:#434EB8" v-if="!insights">
                         <feather type="chevron-down"></feather>
                         </b-col>
                    </b-row>
                    
                    <b-collapse id="insights" v-model="insights" class="mt-0">
                      <b-card><p>Auto-ML engines that foray into organised data layer, identify hidden nuggets, eliminate outliers and prescribe transcripts that empower disruptive ideas and innovations</p></b-card>
                    </b-collapse>
                  </b-card>
                </b-col>
                <b-col cols="12">
                  <b-card 
                      :class="[innovate ? null : 'collapsed' , 'single-box']"
                      :aria-expanded="innovate ? 'true' : 'false'"
                      aria-controls="innovate"
                      @mouseover= "innovate = true"
                      @mouseout= "innovate = false"
                      style="padding: 0px"
                     >
                    <b-row>
                      <b-col cols=9>
                        <h4 >Innovate</h4>
                      </b-col> <b-col cols=2 style="padding-top:20px;color:#434EB8" v-if="!innovate">
                         <feather type="chevron-down"></feather>
                         </b-col>
                    </b-row>
                    
                    <b-collapse id="innovate" v-model="innovate" class="mt-0">
                      <b-card><p>With Data governance and identifying insights becoming a practise, let innovation become an habit</p></b-card>
                    </b-collapse>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          <b-col class="container fluid" cols="12" lg="7">
            <video autoplay playsinline muted loop id="descvideo" class="descvideo">
              <source
                src="../../../assets/img/Metricbees/ourApproach.mp4"
                type="video/mp4"
              />
            </video>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
  <!-- End Design & Development Services -->
</template>

<script>
export default {
  name: "ServicesArea",
    data() {
      return {
        extract: false,
        organise: false,
        visualise: false,
        innovate: false,
        insights: false
      }
    }
};
</script>
<style scoped>
.card-body{
  padding: 0.5rem
}
</style>
