<template>
    <!-- Start Footer Area -->
    <footer class="footer-area ">
        <div class="container">
            <div> <a href="/privacy/Privacy Policy.pdf" download class="ml-4" style="color: blue; text-decoration: underline;"> Privacy </a> </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="copyright-area">
                        <p>Copyright &copy;2021 Metricbees. All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="shape1"><img src="../../assets/img/shape1.png" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
        <!-- <div class="go-top"><feather type="arrow-up"></feather></div> -->
        
        <back-to-top bottom="50px" right="50px">
            <div class="go-top"><feather type="arrow-up"></feather></div>
        </back-to-top>
    </footer>
    <!-- End Footer Area -->
</template>

<script>
import BackToTop from 'vue-backtotop'
export default {
    name: 'Footer',
    components: { BackToTop }
}
</script>