`<template>
  <!-- Start Design & Development Services -->
  <section class="services-area ptb-80">
    <div class="container fluid">
      <div class="title" >
        Benefits
      </div>
       <b-row>
          <b-col class="box" cols="12" lg="5">
            <b-row>
              <b-col cols="12">
                <div class="hero-content" style="padding-bottom:0px; margin-bottom:0px !important">
                  <h1>
           Value creation as a norm determines success</h1>
           </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      <div>
        <div class="container">
        <b-row style="padding: 10px:padding-left: 0px;padding-top:0px">
          <b-col class="box" cols="12" md="6">
                <b-card 
                     overlay
                     img-src="../../../assets/img/Metricbees/image 61@2x.png"
                     img-alt="Card Image"
                      :class="[facts ? null : 'collapsed' , 'single-box']"
                      :aria-expanded="facts ? 'true' : 'false'"
                      aria-controls="facts"
                      @mouseover= "facts = true"
                      @mouseout= "facts = false"
                      style="padding: 0px"
                      footer="Facts and AI Based Decisions"
                     >
                    <b-collapse id="facts" v-model="facts" class="mt-0">
                    <b-card><p>AI powered projections with a human touch empower facts based decisions</p></b-card>
                    </b-collapse>
                  </b-card>
              </b-col>
              
          <b-col class="box" cols="12" md="6">
                <b-card 
                     overlay
                     img-src="../../../assets/img/Metricbees/image 6@2x.png"
                     img-alt="Card Image"
                      :class="[oprEff ? null : 'collapsed' , 'single-box']"
                      :aria-expanded="oprEff ? 'true' : 'false'"
                      aria-controls="oprEff"
                      @mouseover= "oprEff = true"
                      @mouseout= "oprEff = false"
                      style="padding: 0px"
                      footer="Operational Efficacy"
                     >
              
                    <b-collapse id="oprEff" v-model="oprEff" class="mt-0">
                    <b-card><p>Business process automation of routine manual tasks that improves operational process quality.</p></b-card>
                    </b-collapse>
                  </b-card>
              </b-col>
         
        </b-row>
     
         <b-row>
           <b-col class="box" cols="12" md="6">
                <b-card 
                     overlay
                     img-src="../../../assets/img/Metricbees/image 9@2x.png"
                     img-alt="Card Image"
                      :class="[data ? null : 'collapsed' , 'single-box']"
                      :aria-expanded="data ? 'true' : 'false'"
                      aria-controls="data"
                      @mouseover= "data = true"
                      @mouseout= "data = false"
                      style="padding: 0px"
                      footer="Data Governance"
                     >
                    <b-collapse id="data" v-model="data" class="mt-0">
                    <b-card><p>Immediate and reliable data availability for Governance & Regulatory purposes, provides data lineage and eradicates data correction, manipulation errors.</p></b-card>
                    </b-collapse>
                  </b-card>
              </b-col>
          <b-col class="box" cols="12" md="6">
                <b-card 
                     overlay
                     img-src="../../../assets/img/Metricbees/Mask-Group-4.png"
                     img-alt="Card Image"
                      :class="[augmt ? null : 'collapsed' , 'single-box']"
                      :aria-expanded="augmt ? 'true' : 'false'"
                      aria-controls="augmt"
                      @mouseover= "augmt = true"
                      @mouseout= "augmt = false"
                      style="padding: 0px"
                      footer="Top Line Growth"
                     >
              
                    <b-collapse id="augmt" v-model="augmt" class="mt-0">
                    <b-card><p>Analytics that encompass product performance, customer segmentation metrics / targeted campaigns & promotion effectiveness, holistic view of the Organisation KPIs and unlocks new avenues to grow the top line.</p></b-card>
                    </b-collapse>
                  </b-card>
              </b-col>
        </b-row>
        </div>
      </div>
    </div>
  </section>
  <!-- End Design & Development Services -->
</template>

<script>
export default {
  name: "ServicesArea",
  data () {
    return {
      facts: false,
      oprEff: false,
      data: false,
      augmt: false
    }
  }
};
</script>
`
